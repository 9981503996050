import { Cell } from '../grid/Cell';
import { BaseModal } from './BaseModal';

type Props = {
  isOpen: boolean;
  handleClose: () => void;
};

export const InfoModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="How to play" isOpen={isOpen} handleClose={handleClose}>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Guess the <strong>JEWDLE</strong> in 6 tries. After each guess, the
        color of the tiles will change to show how close your guess was to the
        word.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="S" status="correct" />
        <Cell value="H" />
        <Cell value="A" />
        <Cell value="L" />
        <Cell value="O" />
        <Cell value="M" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        The letter S is in the word and in the correct spot.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="J" />
        <Cell value="E" />
        <Cell value="W" />
        <Cell value="I" />
        <Cell value="S" status="present" />
        <Cell value="H" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        The letter S is in the word but in the wrong spot.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="L" />
        <Cell value="A" />
        <Cell value="T" />
        <Cell value="K" status="absent" />
        <Cell value="E" />
        <Cell value="S" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        The letter K is not in the word in any spot.
      </p>

      <p className="mt-4 text-sm text-gray-500 dark:text-gray-300">
        Words can range from English to Hebrew to Yiddish to Aramaic and
        everything in between. Try names of texts, holidays, slang, prayers,
        anything you can think of. Any Jew-ish word is fair game in Jewdle!
      </p>

      <p className="mt-4 text-sm text-gray-500 dark:text-gray-300">
        A{' '}
        <a
          className="underline text-shalomBlue-500 hover:text-shalomBlue-600"
          href="https://www.shalom.edu.au/contact-us/"
        >
          Shalom Collective
        </a>{' '}
        project. Site by{' '}
        <a
          className="underline text-shalomBlue-500 hover:text-shalomBlue-600"
          href="https://www.dabbl.es"
        >
          dabbles
        </a>
        .
      </p>
    </BaseModal>
  );
};

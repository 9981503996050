import classNames from 'classnames';
import { SHALOM_COLORS } from '../../constants/colors';

type Props = {
  index: number;
  size: number;
  label: string;
};

export const Progress = ({ index, size, label }: Props) => {
  const color = SHALOM_COLORS[index % SHALOM_COLORS.length];
  return (
    <div className="flex justify-left m-1">
      <div className="items-center justify-center w-2">{index + 1}</div>
      <div className="rounded-full w-full ml-2">
        <div
          style={{ width: `${5 + size}%` }}
          className={classNames(
            'text-xs font-medium text-center p-0.5 rounded-l-full',
            {
              'bg-shalomPink-500': color === 'shalomPink',
              'bg-shalomOrange-500': color === 'shalomOrange',
              'bg-shalomGreen-500': color === 'shalomGreen',
              'bg-shalomBlue-500': color === 'shalomBlue',
              'text-gray-50': color === 'shalomBlue',
              'text-gray-900': color !== 'shalomBlue',
            }
          )}
        >
          {label}
        </div>
      </div>
    </div>
  );
};
